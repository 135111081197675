import { Injectable } from '@angular/core';
import * as Stor from "firebase/storage";
import { ListResult, Storage } from "@angular/fire/storage";
import { FirebaseFile } from '../../models/firebase-file.model';

class FilesAndFolders
{
  Files: FirebaseFile[];
  Folders: string[];

  constructor(files: FirebaseFile[], folders: string[])
  {
    this.Files = files;
    this.Folders = folders;
  }
}

@Injectable({
  providedIn: 'root'
})
export class FileManagerService {
  constructor(
    private storage: Storage,
  ) { }

  public PushFileToStorage(filesToUpLoad: File[], route: string): Promise<void> {

    let promises: Promise<void>[] = [];

    for (var i = 0; i < filesToUpLoad.length; i++)
    {
      const filePath = route + '/' + filesToUpLoad[i].name;
      const storageRef = Stor.ref(this.storage, filePath);
      const uploadTask = Stor.uploadBytes(storageRef, filesToUpLoad[i]).then();

      promises.push(uploadTask.then());
    }

    return Promise.all(promises).then();
  }

  public async ListAllAtRoute(route: string): Promise<FilesAndFolders>
  {
    const listResult = await Stor.listAll(Stor.ref(this.storage, route)).then();

    let folders: string[] = this.FoldersList(listResult);
    let files: FirebaseFile[] = await this.ListFiles(listResult);

    return new FilesAndFolders(files, folders);
  }

  private FoldersList(listResult: ListResult): string[]
  {
    let folders: string[] = [];
    listResult.prefixes.forEach((currentPrefix) => {
      folders.push(currentPrefix.name);
    });

    return folders;
  }

  private async ListFiles(listResult: ListResult): Promise<FirebaseFile[]>
  {
    let urlPromises: Promise<string>[] = [];
    let files: FirebaseFile[] = [];

    listResult.items.forEach((currentItem) => {
      const extension = currentItem.name.split('.').pop();
      const fileType: FirebaseFile.Type = this.ExtensionToFileType(extension);
      files.push(new FirebaseFile(currentItem.fullPath, currentItem.name, '', fileType));

      urlPromises.push(Stor.getDownloadURL(Stor.ref(this.storage, currentItem.fullPath)).then());
    });

    let allUrlPromises = await Promise.all(urlPromises).then();
    for (var i = 0; i < allUrlPromises.length; i++)
    {
      files[i].DownloadURL = allUrlPromises[i];
    }

    return files;
  }

  private ExtensionToFileType(extension: string | undefined): FirebaseFile.Type {
    switch (extension) {
      case "mp3": {
        return FirebaseFile.Type.Audio;
      }
      case "mp4": {
        return FirebaseFile.Type.Video;
      }
      case "png":
      case "jfif":
      case "jpg": {
        return FirebaseFile.Type.Image;
      }
      case "ghost": {
        return FirebaseFile.Type.Ghost;
      }
      default: {
        return FirebaseFile.Type.Invalid;
      }
    }
  }

  public DeleteFiles(routes: string[]): Promise<void>
  {
    let deletePromises: Promise<void>[] = [];

    routes.forEach((currentRoute) =>
    {
      deletePromises.push(Stor.deleteObject(Stor.ref(this.storage, currentRoute)).then());
    });

    return Promise.all(deletePromises).then();
  }

  public DeleteFolders(routes: string[]): Promise<void>
  {
    let deletePromises: Promise<void>[] = []

    routes.forEach((currentRoute) =>
    {
      return this.ListAllAtRoute(currentRoute).then((filesAndFolders) => {
        filesAndFolders.Files.forEach((currentFile) => { deletePromises.push(this.DeleteFiles([currentFile.FullPath])) });
        filesAndFolders.Folders.forEach((currentFolder => { deletePromises.push(this.DeleteFolders([currentRoute + '/' + currentFolder])) }));
      });
    });

    return Promise.all(deletePromises).then();
  }

  public CreateFolder(route: string): Promise<Stor.UploadResult>
  {
    return Stor.uploadString(Stor.ref(this.storage, route + '/.ghost'), '');
  }
}
