import * as FireB from "firebase/database";
import { Database, DataSnapshot, get } from '@angular/fire/database';
import { Component, OnInit } from '@angular/core';
import { PracticeGame } from "../../models/practice-game.model";
import { ConfirmationDialogComponent } from "../../../generic/components/confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { StorageRouteExplorerComponent } from "../../../storage-manager/components/storage-route-explorer/storage-route-explorer.component";

class PracticeGamesToUpload
{
  gameSongs: string[];
  gameThemes: number[];

  constructor(gameSongs: string[], gameThemes: number[])
  {
    this.gameSongs = gameSongs;
    this.gameThemes = gameThemes;
  }
}

@Component({
  selector: 'app-practice-games-list',
  templateUrl: './practice-games-list.component.html',
  styleUrls: ["./practice-games-list.component.scss"]
})

export class PracticeGamesListComponent implements OnInit{

  public themes: any = [{ text: 'Amber', id: 0 }, { text: 'Green', id: 1 },
  { text: 'Blue', id: 2 }, { text: 'Peach', id: 3 },
  { text: 'Red', id: 4 }, { text: 'Purple', id: 5 },
  { text: 'Pink', id: 6 }, { text: 'Turqoise', id: 7 }];

  private storageRoute: string = "gs://zpln-staging.appspot.com/";
  private route: string = "PracticeGameParameters/";
  private gameSongsRoute: string = "gameSongs";
  private gameThemesRoute: string = "gameThemes";

  public StorageMusicRoute: string = "Music";
  public NumberOfSongsPerEvent = 3;
  public practiceGames: PracticeGame[] = [];
  public isUploadNeeded: boolean = false;

  constructor(
    private fb: Database,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void
  {
    this.GetPracticeGames();
  }

  private GetPracticeGames()
  {
    FireB.get(FireB.ref(this.fb, this.route)).then((data) =>
    {
      const gameSongs: any[] = this.snapshotToArray(data.child(this.gameSongsRoute));
      const gameThemes: any[] = this.snapshotToArray(data.child(this.gameThemesRoute));
      this.GeneratePracticeGames(gameSongs, gameThemes);

    }).catch((error) =>
    {
      console.log("Could not retrieve " + this.route + " with error: " + error)
    });
  }

  private snapshotToArray(snapshot: DataSnapshot)
  {
    let returnArr : any = [];

    snapshot.forEach(function (childSnapshot)
    {
      var item = childSnapshot.val();
      returnArr.push(item);
    });

    return returnArr;
  }

  private GeneratePracticeGames(gameSongs: any[], gameThemes: any[]):void
  {
    for (var i = 0; i < gameSongs.length; i++)
    {
      this.practiceGames.push(new PracticeGame(gameSongs[i], gameThemes[i]));
    }
  }

  public UploadPracticeGames(): void
  {
    const practiceGamesToUpload: any = this.NewPracticeGamesToUpload();

    FireB.update(FireB.ref(this.fb, this.route), practiceGamesToUpload).then(() =>
    {
      this.isUploadNeeded = false;
      this.OpenPracticeGamesUploadConfirmationDialog();
    }).catch((err) =>
    {
      console.error(err);
    });

  }

  private NewPracticeGamesToUpload(): PracticeGamesToUpload
  {
    const gameSongs: string[] = [];
    const gameThemes: number[] = [];

    for (var i = 0; i < this.practiceGames.length; i++)
    {
      gameSongs.push(this.practiceGames[i].gameSongRoute);
      gameThemes.push(this.practiceGames[i].gameThemeIndex);
    }

    return new PracticeGamesToUpload(gameSongs, gameThemes);
  }

  private OpenPracticeGamesUploadConfirmationDialog()
  {
    this.dialog.open(ConfirmationDialogComponent, {
      data:
      {
        mainText: "Practice games have been uploaded correctly",
        confirmText: "Continue",
        canCancel: false,
      }
    })
  }

  public CreateNewPracticeGame(): void
  {
    this.practiceGames.push(new PracticeGame("", 0));
    this.isUploadNeeded = true;
  }

  public OpenConfirmationDialogToDelete(index: number): void
  {
    this.dialog.open(ConfirmationDialogComponent, {
      data:
      {
        mainText: "Are you sure you want to delete this game? Games won't be completely deleted until changes are uploaded.",
      }
    }).afterClosed().subscribe((result) => {
      if (result) { this.DeletePracticeGame(index); }
    });
  }

  private DeletePracticeGame(index: number): void
  {
    this.practiceGames.splice(index, 1);
    this.isUploadNeeded = true;
  }

  public RouteExplorerDialog(folderLocation: string, index: number): void
  {
    this.dialog.open(StorageRouteExplorerComponent,
      {
        maxHeight: '100%',
        maxWidth: '100%',
        height: '90%',
        width: '65%',
        data:
        {
          route: folderLocation
        }
      }).afterClosed().toPromise().then((result) =>
      {
        if (result)
        {
          this.practiceGames[index].gameSongRoute = this.storageRoute + result.FullPath;
          this.isUploadNeeded = true;
        }
      });
  }

  public SimpleStorageRoute(originalRoute: string): string | undefined {
    return originalRoute.split(this.storageRoute).pop();
  }

  public FullStorageRoute(text: string | undefined): string {
    const fullRoute = this.storageRoute + text;

    return fullRoute;
  }
}
