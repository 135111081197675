import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  public mainText!: string;
  public confirmText!: string;
  public cancelText!: string;
  public canCancel!: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>
  )
  {
    this.dialogRef.disableClose = true;
    this.mainText = this.data.mainText ? this.data.mainText : "Are you sure you want to perform this action?";
    this.confirmText = this.data.confirmText ? this.data.confirmText : "Confirm";
    this.cancelText = this.data.cancelText ? this.data.cancelText : "Cancel";
    this.canCancel = this.data.canCancel != null ? this.data.canCancel : true;
  }

  ngOnInit(): void
  {

  }

  OnButton(value:boolean)
  {
    this.dialogRef.close(value);
  }
}
