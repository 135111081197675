import { Component, OnInit } from '@angular/core';
import * as FireB from "firebase/database";
import { Database } from '@angular/fire/database';
import { GameParameters } from '../../models/game-parameters.model';

@Component({
  selector: 'app-game-parameters-list',
  templateUrl: './game-parameters-list.component.html',
  styleUrls: ['./game-parameters-list.component.scss', '../../../generic/style-sheets/event-button.scss']
})
export class GameParametersListComponent implements OnInit {

  private route: string = "Parameters/";

  private gameParameters: GameParameters = new GameParameters(null);

  public NeedsUpdate: boolean = false;
  public get GameParameters() { return this.gameParameters; }

  constructor(
    private fb: Database,
  ) { }

  ngOnInit(): void
  {
    this.GetGameParameters();
  }

  private GetGameParameters() {
    FireB.get(FireB.ref(this.fb, this.route)).then((data) =>
    {
      this.gameParameters = new GameParameters(data.toJSON());
    }).catch((error) =>
    {
      console.log("Could not retrieve " + this.route + " with error: " + error)
    });
  }

  public UpdateGameParameters()
  {
    FireB.update(FireB.ref(this.fb, this.route), this.gameParameters).then((data) =>
    {
      alert("Parameters updated correctly")
    }).catch((error) =>
    {
      alert("Parameters couldn't be updated")
    });
  }

  public trackByFn(index: any, item: any)
  {
    return index;
  }
}
