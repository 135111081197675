export class BaseEvent {

  shouldUpdate: boolean;
  gameSongs: string[];
  gameThemes: number[];
  name!: string;

  constructor(data: any | null)
  {
    this.shouldUpdate = false;
    this.gameSongs = data !== null ? Object.values(data?.gameSongs) : ["", "", ""];
    this.gameThemes = data !== null ? Object.values(data?.gameThemes) : [0, 1, 2];
    this.name = data != null ? data.name : "NEW EVENT";
  }
}
