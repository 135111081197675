import {Component, Input} from '@angular/core';
import {ZplnEvent} from '../../models/zplnEvent';
import {Database} from "@angular/fire/database";
import * as FireB from "firebase/database";
import { ZplnEventListComponent } from '../zpln-event-list/zpln-event-list.component';
import { BaseEventDetail } from '../../../models/base-event-detail.model';
import { MatDialog } from "@angular/material/dialog";
import { StorageRouteExplorerComponent } from '../../../../storage-manager/components/storage-route-explorer/storage-route-explorer.component';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-zpln-event-detail',
  templateUrl: './zpln-event-detail.component.html',
  styleUrls: ['./zpln-event-detail.component.scss',
              '../../../../generic/style-sheets/checkbox.scss']
})
export class ZplnEventDetailComponent extends BaseEventDetail
{

  private storageRoute: string;

  public StorageImageRoute: string = "Images";
  public StorageMusicRoute: string = "Music";
  public StorageVideoRoute: string = "Video";

  @Input() public override currentEvent: ZplnEvent = new ZplnEvent(null);
  @Input() public override currentEventIndex: number = 0;

  public types: any = [{ text: 'Cash Only', id: 5 }, { text: 'Coins Only', id: 6 }];

  constructor(
    private fb: Database,
    private eventList: ZplnEventListComponent,
    public dialog: MatDialog,
  ) {
    super();
    this.route = 'Active_Tournaments/';
    this.storageRoute = "gs://" + environment.firebase.storageBucket + "/";
  }

  public get StartDate(): Date
  {
    const fullDate = new Date(this.currentEvent.startDate);
    return fullDate;
  }

  public set StartDate(date: Date)
  {
    let newDate = this.StartDate;
    const dateSlices = date.toString().split('-');
    newDate.setFullYear(Number(dateSlices[0]));
    newDate.setMonth(Number(dateSlices[1]) - 1);
    newDate.setDate(Number(dateSlices[2]));

    this.currentEvent.startDate = newDate.toUTCString();
  }

  public set StartTime(time: string)
  {
    let newDate = new Date(this.StartDate);
    const timeSlices = time.split(':');
    newDate.setHours(Number(timeSlices[0]));
    newDate.setMinutes(Number(timeSlices[1]));

    this.currentEvent.startDate = newDate.toUTCString();
  }

  public get RemainingDays()
  {
    return this.currentEvent.remainsHours / 24;
  }

  public set RemainingDays(number: number)
  {
    this.currentEvent.remainsHours = number * 24;
  }

  public SimpleStorageRoute(originalRoute: string): string | undefined
  {
    return originalRoute.split(this.storageRoute).pop();
  }

  public FullStorageRoute(text: string | undefined): string
  {
    const fullRoute = this.storageRoute + text;

    return fullRoute;
  }

  public RouteExplorerDialog(folderLocation: string, propertyName: string, returnURL: boolean = false, index?: number): void
  {
    this.dialog.open(StorageRouteExplorerComponent,
      {
        maxHeight: '100%',
        maxWidth: '100%',
        height: '90%',
        width: '65%',
        data:
        {
          route: folderLocation
        }
      }).afterClosed().toPromise().then((result) =>
      {
        if (result)
        {
          if (index !== undefined) Reflect.get(this.currentEvent, propertyName)[index] = this.URLOrPath(returnURL, result);
          else Reflect.set(this.currentEvent, propertyName, this.URLOrPath(returnURL, result));
          
          this.currentEvent.shouldUpdate = true;
        }
      });
  }

  private URLOrPath(returnURL: boolean, data: any): boolean
  {
    return returnURL ? data.DownloadURL : this.storageRoute + data.FullPath;
  }

  public override UpdateEvent()
  {
    FireB.update(FireB.ref(this.fb, this.route + this.currentEvent.eventId), this.currentEvent).then(() =>
    {
      this.currentEvent.shouldUpdate = false;
    }).catch((err) =>
    {
      alert(err);
    });
  }

  public override CloneEvent(eve: ZplnEvent)
  {
    this.eventList.CreateEvent(eve);
  }

  public override DeleteEvent(): void
  {
    this.eventList.OpenConfirmationDialogToDelete(this.currentEventIndex);
  }

  public IsEventActiveAndDateNotToday(event: ZplnEvent): boolean
  {
    return event.isActiveToday == 1 && this.IsEventDateOverToday(event);
  }

  private IsEventDateOverToday(event: ZplnEvent): boolean
  {
    const today = new Date();
    const eventStartDate = new Date(event.startDate);

    return eventStartDate >= today;
  }
}
