import { Component, Inject, OnInit } from '@angular/core';
import { Database } from '@angular/fire/database';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-vault-dialog',
  templateUrl: './vault-dialog.component.html',
  styleUrls: ['./vault-dialog.component.scss']
})

export class VaultDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<VaultDialogComponent>,
    private db: Database,
  ) { }

  public ngOnInit(): void
  {

  }

  public Close(): void
  {
    this.dialogRef.close();
  }

  public UpdateVault(): void
  {
    this.data.userListRef.UpdateUser(this.data.userInd.user);
    this.data.userInd.mustBeUpdated = false;
  }
}
