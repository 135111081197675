<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js"></script>
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>

<body>
  <nav class="navbar navbar-inverse">
    <div class="container-fluid">
      <div class="navbar-header">
        <a style="color:white" class="navbar-brand">ZPLN Control Panel</a>
      </div>
      <ul class="nav navbar-nav">
        <li [ngClass]="currentUrl == currentTab.url ? 'active' : 'ah'" *ngFor="let currentTab of tabs">
          <a (click)="currentUrl = currentTab.url" routerLink="{{currentTab.url}}">{{currentTab.text}}</a>
        </li>
      </ul>
    </div>
  </nav>
  <div class="container">
    <router-outlet></router-outlet>
  </div>  
</body>
