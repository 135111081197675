import { Component, OnInit } from '@angular/core';
import { User } from '../../models/user';
import { Database } from "@angular/fire/database";
import { MatDialog } from "@angular/material/dialog";
import { VaultDialogComponent } from '../vault-dialog/vault-dialog.component';
import { ConfirmationDialogComponent } from '../../../generic/components/confirmation-dialog/confirmation-dialog.component';
import { UserGameInfoDialogComponent } from '../user-game-info-dialog/user-game-info-dialog.component';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { UsersService } from '../../services/users-service.service';

class UserListing
{
  public user!: User;
  public mustBeUpdated: boolean = false;

  constructor(newUser: User)
  {
    this.user = newUser;
  }
}

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {

  public users: UserListing[] = [];
  public PaginateOptions: number[] = [10, 20, 50, 100];
  public SearchByOptions: string[] = ['userName', 'phone', 'userId'];
  public RowsPerPage: number = 20;
  public SearchBy: string = 'userName';
  public PaginationIndex: number = 0;
  public TotalUsersCount: number = 0;
  public prevValues: any[] = [];
  public nextValue: string | null = "";
  public SearchFilter = "";

  constructor(
    public dialog: MatDialog,
    private usersService: UsersService,
  ) { }

  ngOnInit(): void
  {
    this.UpdateUsersListing();
    this.UpdateTotalUsersCount();
  }

  private UpdateUsersListing(startValue?: string | null, isSearch?: boolean): void
  {
    this.usersService.GetUsers(this.RowsPerPage, this.SearchBy, startValue, isSearch).then((users) =>
    {
      this.users = [];
      users.forEach((currentUser) =>
      {
        this.users.push(new UserListing(currentUser));
      })

      const key = this.SearchBy as keyof User;
      this.nextValue = users[users.length - 1][key].toString();
      console.log(this.nextValue);
    });
  }

  private async UpdateTotalUsersCount()
  {
    this.usersService.GetTotalUsersCount().then((totalCount: number) => this.TotalUsersCount = totalCount);
  }

  public NextPage(): void
  {
    this.PaginationIndex += this.RowsPerPage;
    this.prevValues.push(this.users[0].user[this.SearchBy as keyof User]);

    this.UpdateUsersListing(this.nextValue);
  }

  public PreviousPage(): void
  {
    this.PaginationIndex -= this.RowsPerPage;
    const prevValue = this.prevValues.pop();

    this.UpdateUsersListing(prevValue);
  }

  public ResetPagination()
  {
    this.PaginationIndex = 0;
    this.prevValues = [];
    this.SearchFilter = "";
    this.UpdateUsersListing();
  }

  public Search(): void
  {
    if (this.SearchFilter == "") this.UpdateUsersListing();
    else this.UpdateUsersListing(this.SearchFilter, true);
  }

  public DeleteUser(user: User): void
  {
    this.usersService.DeleteUser(user).then(() =>
    {
      this.users.map((u, i) =>
      {
        if (u.user === user) this.users.splice(i, 1);
      });
    });
  }

  public UpdateUser(user: User)
  {
    this.usersService.UpdateUser(user).then(() =>
    {
      this.dialog.open(ConfirmationDialogComponent,
        {
          data:
          {
            mainText: "User " + user.userName + " updated correctly",
            canCancel: false,
          }
        });
    });
  }

  public OpenVaultDialog(userInd: UserListing): void
  {
    this.dialog.open(VaultDialogComponent,
    {
      data:
      {
        userInd: userInd,
        userListRef: this
      }
    });
  }

  public OpenGameInfoDialog(user: User): void
  {
    this.dialog.open(UserGameInfoDialogComponent,
      {
        maxHeight: '90vh',
        data:
        {
          userName: user.userName,
          globalAverage: user.globalAverage,
          tournaments: user.tournaments,
        }
      });
  }

  public OpenConfirmationDialogToDelete(user: User)
  {
    this.dialog.open(ConfirmationDialogComponent, {
      data:
      {
        mainText: "Are you sure you want to delete user: " + user.userName + " ?",
      }
    }).afterClosed().subscribe((result) =>
    {
      if (result) { this.DeleteUser(user); }
    });
  }
}

