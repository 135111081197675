export class User
{
  userId!: string;
  userName!: string;
  phone!: number;
  level!: number;
  levelName!: string;
  globalAverage!: number;
  tournaments!: any[];
  vault!: any[];

  constructor(data: any, key: string | null)
  {
    this.userId = data.userId ? data.userId : key;
    this.userName = data.userName;
    this.phone = data.phone;
    this.level = data.level;
    this.levelName = data.levelName;
    this.vault = data.vault;
    this.globalAverage = data.globalAverage;
    this.FillTorunaments(data.Tournaments);
  }

  private FillTorunaments(data:any): void
  {
    if (data) {
      this.tournaments = Object.values(data);

      for (let currentTournament of this.tournaments) {
        if (currentTournament.games) {
          currentTournament.games = Object.values(currentTournament.games);
        }
      }
    }
  }
}
