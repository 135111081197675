<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.1/css/all.min.css" integrity="sha256-2XFplPlrFClt0bIdPgpz8H7ojnk10H69xRqd9+uTShA=" crossorigin="anonymous" />
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/ionicons/4.5.6/css/ionicons.min.css" integrity="sha512-0/rEDduZGrqo4riUlwqyuHDQzp2D1ZCgH/gFIfjMIL5az8so6ZiXyhf1Rg8i6xsjv+z/Ubc4tt1thLigEcu6Ug==" crossorigin="anonymous" referrerpolicy="no-referrer" />

  <div class="container flex-grow-1 light-style container-p-y">
    <div class="container-m-nx container-m-ny bg-lightest mb-3">
      <ol class="breadcrumb text-big container-p-x py-3 m-0">
        <a (click)="GoToRouteIndex(0)">Source</a>
        <li *ngFor="let currentRoute of Routes;  let i = index;" [attr.data-index]="i" class="breadcrumb-item">
          <a (click)="GoToRouteIndex(i)">{{currentRoute}}</a>
        </li>
      </ol>

      <hr class="m-0" />

      <div class="file-manager-actions container-p-x py-2">
        <div>
          <button type="button" class="btn btn-primary mr-2" (click)="OpenDialogToUploadFiles()"><i class="ion ion-md-cloud-upload"></i>&nbsp; Upload</button>
          <div class="btn-group mr-2">
            <button (click)="OpenDialogToNameFolder()" type="button" class="btn btn-default md-btn-flat dropdown-toggle px-2">New Folder</button>
            <div class="dropdown">
              <button class="btn btn-default md-btn-flat dropdown-toggle px-2"><i class="ion ion-ios-settings"></i></button>
              <div class="dropdown-content">
                <button (click)="OpenConfirmationDialogToRemoveFiles()" style="width: 100%" [disabled]="!(SelectedFiles.size > 0 || SelectedFolders.size > 0)"
                        class="btn btn-default md-btn-flat dropdown-toggle px-2">Remove Selected</button>
                <button (click)="SelectUnselectAll()" style="width: 100%"
                        class="btn btn-default md-btn-flat dropdown-toggle px-2">Select/Unselect All</button>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-left: auto; margin-right: 0">
          <div style="margin-left: 2em; margin-right: 2em;" class="icone lupa"></div>
          <input type="text" style="color:black;" [(ngModel)]="SearchFilter" />
        </div>
      </div>

      <hr class="m-0" />
    </div>

    <div class="file-manager-container file-manager-col-view">
      <div class="file-manager-row-header">
        <div class="file-item-name pb-2">Filename</div>
        <div class="file-item-changed pb-2">Changed</div>
      </div>

      <div *ngIf="StartingRoute != CurrentRoute">
        <div class="file-item" (click)="GoToPreviousFolder()">
          <div class="file-item-icon file-item-level-up fas fa-level-up-alt text-secondary"></div>
          <a href="javascript:void(0)" class="file-item-name">
            ..
          </a>
        </div>
      </div>

      <div *ngFor="let currentFolder of FirebaseFolders;  let i = index;" [attr.data-index]="i">
        <div class="file-item" [ngStyle]="{'border-color': (IsFolderSelected(CurrentRoute + '/' + currentFolder)) ? 'red' : ''}">
          <label class="file-item-checkbox custom-control custom-checkbox">
            <input (change)="SelectFolder($event, CurrentRoute + '/' + currentFolder)" [ngModel] ="IsFolderSelected(CurrentRoute + '/' + currentFolder)" type="checkbox" class="custom-control-input" />
          </label>
          <div (click)="NavigateToFolder(currentFolder)">
            <div class="file-item-icon far fa-folder text-secondary"></div>
            <div class="file-item-name" [ngStyle]="{'text-decoration': (IsFolderSelected(CurrentRoute + '/' + currentFolder)) ? 'underline red' : ''}">
              {{currentFolder}}
            </div>
          </div>
        </div>
      </div>

      <div *ngFor="let currentFile of FirebaseFiles;  let i = index;" [attr.data-index]="i">
        <div *ngIf="currentFile.Name.toUpperCase().includes(SearchFilter.toUpperCase()) && currentFile.Type != 4">
          <a href="{{currentFile.DownloadURL}}" target="_blank">
            <div class="file-item" [ngStyle]="{'border-color': (IsFileSelected(currentFile.FullPath)) ? 'red' : ''}">
              <label class="file-item-checkbox">
                <input (change)="SelectFile($event, currentFile.FullPath)" [ngModel] ="IsFileSelected(currentFile.FullPath)" type="checkbox" class="custom-control-input" />
              </label>
              <div *ngIf="currentFile.Type == 1" class="file-item-icon far fa-file-video text-secondary"></div>
              <div *ngIf="currentFile.Type == 2" class="file-item-icon far fa-file-audio text-secondary"></div>
              <div *ngIf="currentFile.Type == 0" class="file-item-img" style="background-image: url({{currentFile.DownloadURL}}); "></div>
              <div *ngIf="currentFile.Type == 3" class="file-item-icon far fa-file text-secondary"></div>
              <div class="file-item-name" [ngStyle]="{'text-decoration': (IsFileSelected(currentFile.FullPath)) ? 'underline red' : ''}">
                {{currentFile.Name}}
              </div>
              <div class="file-item-changed">02/20/2018</div>
              <div class="file-item-actions btn-group">
              </div>
            </div>
          </a>
        </div>
      </div>

    </div>
  </div>
