export class FirebaseFile
{
  public FullPath: string;
  public Name: string;
  public DownloadURL: string;
  public Type: FirebaseFile.Type;

  constructor(fullpath: string, name: string, downloadURL:string, type: FirebaseFile.Type)
  {
    this.FullPath = fullpath;
    this.Name = name;
    this.DownloadURL = downloadURL;
    this.Type = type;
  }
}

export namespace FirebaseFile
{
  export enum Type
  {
    Image,
    Video,
    Audio,
    Invalid,
    Ghost
  }
}
