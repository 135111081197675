<ng-container *ngIf="practiceGames && practiceGames.length>0">
  <div>
    <h2>Practice Games ({{practiceGames.length}}):</h2>
  </div>
  <div [ngClass]="isUploadNeeded ? 'card-pending' : 'card-ready'" class="card">
    <mat-card>
      <table class="user-table">
        <thead>
          <tr>
            <th>Event</th>
            <th>Game</th>
            <th>Song</th>
            <th>Action</th>
            <th>Theme</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let currentPracticeGame of practiceGames;  let i = index" [attr.data-index]="i"
              [ngStyle]="!(i % NumberOfSongsPerEvent) && {'border-top' : '0.2em solid lightgrey'} || null">

            <td *ngIf="!(i % NumberOfSongsPerEvent); else empty"><b>Practice {{(i / NumberOfSongsPerEvent) + 1}}</b></td>
            <ng-template #empty>
              <td></td>
            </ng-template>

            <td>{{i + 1}}</td>
            <td>
              <input type="text" class="form-control"
                     required (ngModelChange)="isUploadNeeded = true; currentPracticeGame.gameSongRoute = FullStorageRoute($event);"
                     [ngModel]="SimpleStorageRoute(currentPracticeGame.gameSongRoute)" />
            </td>
            <td>
              <button (click)="RouteExplorerDialog(StorageMusicRoute, i)">Edit</button>
            </td>
            <td>
              <select type="number" class="form-control"
                      required (ngModelChange)="isUploadNeeded = true;" [(ngModel)]="currentPracticeGame.gameThemeIndex">
                <option *ngFor="let theme of themes" [ngValue]="theme.id">{{theme.text}}</option>
              </select>
            </td>
            <td>
              <button (click)="OpenConfirmationDialogToDelete(i)">X</button>
            </td>
          </tr>
        </tbody>
      </table>

      <div>
        <button style="display: block; margin: 0 auto;" (click)="CreateNewPracticeGame()">Create new</button>
      </div>

    </mat-card>
    <div *ngIf="isUploadNeeded" style="color: red">
      <br>
      Upload changes to save
      <button style="color:red" (click)="UploadPracticeGames()">Upload</button>
    </div>
  </div>
</ng-container>
