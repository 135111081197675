<div>
  <h2>{{CurrentLeaderboard.eventName}}:</h2>
</div>
<mat-card>
  <table class="leaderboard-table">
    <thead>
      <tr>
        <th>Rank</th>
        <th>Total</th>
        <th>User</th>
        <th>Weekly Average</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let currentUser of CurrentLeaderboard.leaderboardUsers;  let i = index" [attr.data-index]="i">
        <td>{{i + 1}}</td>
        <td>{{currentUser.total}}</td>
        <td [ngStyle]="{'color': (currentUser.exists) ? 'white' : 'red'}">{{currentUser.name}}</td>
        <td>{{currentUser.globalAverage}}</td>
      </tr>
    </tbody>
  </table>
</mat-card>
