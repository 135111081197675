import { BaseEvent } from "../../models/base-event.model";

export class ZplnEvent extends BaseEvent {
  autoRenewal!: boolean;

  backgroundImage!: string;
  brandLogoImage!: string;
  brandTextImage!: string;
  creditprizeScreen!: string;
  videoURL!: string;

  currentPhase: number = 1;
  numberOfPhases: number = 1;

  coinsCost!: number;
  coinsJackpot!: number;
  eventId!: string;
  eventType!: number;
  remainsHours!: number;
  startDate!: string;

  isActiveToday!: number;
  isZplnEvent!: boolean;
  isJackpotEvent!: boolean;
  isHighScoreEvent!: boolean;
  isBrandEvent!: boolean;
  isAudienceEvent!: boolean;

  gameSeeds: number[];

  prizes: any[] = [{}]
  numberOfPrizes: number = 0;

  constructor(data: any | null) {
    super(data);
    this.autoRenewal = data != null ? data.autoRenewal : false;
    this.backgroundImage = data != null ? data.backgroundImage : "";
    this.brandLogoImage = data != null ? data.brandLogoImage : "";
    this.brandTextImage = data != null ? data.brandTextImage : "";
    this.creditprizeScreen = data != null ? data.creditprizeScreen : "";
    this.videoURL! = data != null ? data.videoURL : "";

    this.coinsCost = data != null ? data.coinsCost : 50;
    this.coinsJackpot = data != null ? data.coinsJackpot : 10000;
    this.eventId = data != null ? data.eventId : Math.floor(Math.random() * 10000);
    this.eventType = data != null ? data.eventType : 0;
    this.remainsHours = data != null ? data.remainsHours : 48;
    this.startDate = data != null ? data.startDate : new Date();
    this.isActiveToday = data != null ? data.isActiveToday : 0;

    this.gameSeeds = [Math.round(Math.random() * 100000),
                      Math.round(Math.random() * 100000),
                      Math.round(Math.random() * 100000)];


    this.numberOfPrizes = data != null ? data.numberOfPrizes : 25;
    this.prizes = data != null ? Object.values(data.prizes) : Array.apply(null, Array(this.numberOfPrizes)).map(function (x, i) { return { rank: i + 1 } });
    this.isZplnEvent = data != null ? data.isZplnEvent : false;
    this.isJackpotEvent = data != null ? data.isJackpotEvent : false;
    this.isHighScoreEvent = data != null ? data.isHighScoreEvent : false;
    this.isBrandEvent = data != null ? data.isBrandEvent : false;
    this.isAudienceEvent = data != null ? data.isAudienceEvent : false;
  }
}
