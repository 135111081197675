import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Components
import { UsersListComponent } from './user/components/users-list/users-list.component';
import { GameParametersListComponent } from './game-parameters/components/game-parameters-list/game-parameters-list.component';
import { ZplnEventListComponent } from './events/zpln-event/components/zpln-event-list/zpln-event-list.component';
import { PracticeGamesListComponent } from './practice-games/components/practice-games-list/practice-games-list.component';
import { LeaderboardsListComponent } from './leaderboards/components/leaderboards-list/leaderboards-list.component';
import { StorageManagerComponent } from './storage-manager/components/storage-manager/storage-manager.component'
import { AuthLoginComponent } from './auth-login/components/auth-login/auth-login.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'events' },
  { path: 'login', component: AuthLoginComponent },
  { path: 'users', component: UsersListComponent },
  { path: 'events', component: ZplnEventListComponent },
  { path: 'practice-games', component: PracticeGamesListComponent },
  { path: 'game-parameters', component: GameParametersListComponent },
  { path: 'leaderboards', component: LeaderboardsListComponent },
  { path: 'storage', component: StorageManagerComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
