<!-- When there are users -->
<div>
<h2>Showing Users ({{PaginationIndex + 1}}-{{PaginationIndex + users.length}}) of ({{TotalUsersCount}})</h2>
<table style="width: 100%">
  <tr>
    <td>
      <table>
        <tr>
          <td>
            Rows per page
          </td>
          <td>
            <select type="number" required [(ngModel)]="RowsPerPage" (ngModelChange)="ResetPagination();">
              <option *ngFor="let currentOption of PaginateOptions" [ngValue]="currentOption">{{currentOption}}</option>
            </select>
          </td>
          <td>
            <button (click)="PreviousPage()" [disabled]="prevValues.length == 0">
              <
            </button>
          </td>
          <td>
            <button (click)="NextPage()" [disabled]="users.length < RowsPerPage">
              >
            </button>
          </td>
        </tr>
      </table>
    </td>
    <td>
      <table style="float: right">
        <tr>
          <td>
            Search by:
            <select type="string" required [(ngModel)]="SearchBy" (ngModelChange)="ResetPagination();">
              <option *ngFor="let currentOption of SearchByOptions" [ngValue]="currentOption">{{currentOption}}</option>
            </select>
          </td>
          <td>
            <input type="text" style="color:black;" [(ngModel)]="SearchFilter" />
          </td>
          <td>
            <button (click)="Search();"> Search </button>
          </td>
        </tr>
      </table>
    </td>
  </tr>
</table>
</div>

<ng-container *ngIf="users && users.length>0">

  <mat-card style="display: inline-block; width: 100%">
    <table class="user-table">
      <thead>
        <tr>
          <th>Username</th>
          <th>Phone</th>
          <th>Level</th>
          <th>Vault</th>
          <th>Game Info</th>
          <th>Id</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let currentUserInd of users;  let i = index" [attr.data-index]="i">
          <td [ngStyle]="{'border-bottom-color': !currentUserInd.user.userName ? 'red' : ''}">{{currentUserInd.user.userName}}</td>
          <td [ngStyle]="{'border-bottom-color': !currentUserInd.user.phone ? 'red' : ''}">{{currentUserInd.user.phone}}</td>
          <td>Level {{currentUserInd.user.level}}, {{currentUserInd.user.levelName}}</td>
          <td>
            <button style="width:100%; height:100%;" [ngStyle]="{color: currentUserInd.mustBeUpdated ? 'red' : 'black'}"
                    (click)="OpenVaultDialog(currentUserInd)">
              Info
            </button>
          </td>
          <td>
            <button style="width:100%; height:100%; color:black" (click)="OpenGameInfoDialog(currentUserInd.user)">Info</button>
          </td>
          <td>{{currentUserInd.user.userId}}</td>
          <td>
            <button (click)="OpenConfirmationDialogToDelete(currentUserInd.user)">X</button>
          </td>
        </tr>
      </tbody>
    </table>
  </mat-card>
</ng-container>

<!-- User list is empty -->
<ng-container *ngIf="users && users.length<1">
  <h2>There is no users</h2>
</ng-container>



