<div class="dialog">
  <h1>{{data.userName}}</h1>
  <h4>Gloval Avg: {{data.globalAverage}} </h4>
  <div style="display:inline-block" *ngFor="let currentEvent of data.tournaments; let i = index" [attr.data-index]="i">
    <div style="border: 1px solid black; padding: 1em;">
      <h3>{{currentEvent.eventName}}</h3>
      <table>
        <thead>
          <tr>
            <th>Game</th>
            <th>Highscore</th>
            <th>Coins</th>
            <th>Credits</th>
          </tr>
        </thead>
        <tbody *ngFor="let currentGame of currentEvent.games; let i = index" [attr.data-index]="i">
          <tr>
            <td>{{i + 1}}</td>
            <td>{{currentGame.highScore}}</td>
            <td>{{currentGame.coins}}</td>
            <td>{{currentGame.credits}}</td>
          </tr>
        </tbody>
      </table>
      <table style="width: 100%;">
        <tbody>
          <tr>
            <td>Avg: {{currentEvent.average}}</td>
            <td>Total: {{currentEvent.total}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <br>
  </div>
</div>
