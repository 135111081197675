<button class="close" (click)="Close()">X</button>
<h1>Vault</h1>
<h2>{{data.userInd.user.userName}}</h2>
<div *ngIf="data.userInd.mustBeUpdated">
  <h3 style="color:red;">Data not saved, must be uploaded</h3>
  <button style="color:red;" (click)="UpdateVault()">Upload</button>

  <br />
  <br />
</div>

<table>
  <tbody>
    <tr>
      <td> Cash </td>
      <td><input style="text-indent: 0px;" type="number" [(ngModel)]="data.userInd.user.vault.cash" (ngModelChange)="data.userInd.mustBeUpdated = true;" /></td>
    </tr>
    <tr>
      <td> Coins </td>
      <td><input type="number" [(ngModel)]="data.userInd.user.vault.coins" (ngModelChange)="data.userInd.mustBeUpdated = true;" /></td>
    </tr>
    <tr>
      <td> Coins Value </td>
      <td><input type="number" [(ngModel)]="data.userInd.user.vault.coinsValue" (ngModelChange)="data.userInd.mustBeUpdated = true;" /></td>
    </tr>
    <tr>
      <td> Lira </td>
      <td><input type="number" [(ngModel)]="data.userInd.user.vault.lira" (ngModelChange)="data.userInd.mustBeUpdated = true;" /></td>
    </tr>
    <tr>
      <td> Lira Mined </td>
      <td><input type="number" [(ngModel)]="data.userInd.user.vault.liraMined" (ngModelChange)="data.userInd.mustBeUpdated = true;" /></td>
    </tr>
  </tbody>
</table>
