import { Injectable } from '@angular/core';
import * as FireB from "firebase/database";
import { Database } from "@angular/fire/database";
import { AngularFireDatabase, QueryFn } from '@angular/fire/compat/database';
import { User } from '../models/user';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class UsersService
{
  private route: string = "Users/";

  constructor(
    private fb: Database,
    private db: AngularFireDatabase,
  ) { }

  public async GetUsers(numberOfElements: number, searchByField: string, startValue?: string | null, isSearch?: boolean): Promise<User[]>
  {
    let users: User[] = [];

    const usersResponse = await firstValueFrom(this.db.list(this.route, this.GetQuery(searchByField, numberOfElements, startValue, isSearch)).snapshotChanges());

    usersResponse.forEach((currentUser) =>
    {
      users.push(new User(currentUser.payload.toJSON(), currentUser.key));
    });

    return users;
  }

  private GetQuery(searchByField: string, numberOfElements: number, startValue?: string | null, isSearch?: boolean): QueryFn
  {
    let query: QueryFn;
    if (startValue && !isSearch) query = ref => { return ref.orderByChild(searchByField).startAt(startValue).limitToFirst(numberOfElements + 1); };
    else if (!startValue && !isSearch) query = ref => { return ref.orderByChild(searchByField).limitToFirst(numberOfElements + 1); };
    else query = ref => { return ref.orderByChild(searchByField).startAt(startValue!).endAt(startValue! + "\uf8ff").limitToFirst(numberOfElements + 1); };

    return query;
  }

  public async GetTotalUsersCount(): Promise<number>
  {
    // REESCRIBIR USANDO ALGUN CONTADOR MONTADO DESDE SERVIDOR PARA AÑADIR USUARIOS
    let response = await FireB.get(FireB.ref(this.fb, this.route));
    return response.size;
  }

  public DeleteUser(user: User): Promise<void>
  {
    return FireB.remove(FireB.ref(this.fb, this.route + user.userId)).then();
  }

  public UpdateUser(user: User) : Promise<void>
  {
    return FireB.update(FireB.ref(this.fb, this.route + user.userId), user).then();
  }
}
