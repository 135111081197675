import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../../generic/components/confirmation-dialog/confirmation-dialog.component';
import { FileManagerService } from '../../services/file-manager/file-manager.service';
import { StorageManagerComponent } from '../storage-manager/storage-manager.component';

@Component({
  selector: 'app-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.scss']
})
export class FileUploadDialogComponent implements OnInit {

  public UploadRoute: string;
  public FilesToUpload: File[] | null = null;
  public isUploading: boolean = false;

  public storageManager: StorageManagerComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FileUploadDialogComponent>,
    public fileManagerService: FileManagerService,
    public dialog: MatDialog,
  )
  {
    this.UploadRoute = data.uploadRoute;
    this.storageManager = data.storageManager;
  }

  ngOnInit(): void { }

  SelectFile(event: any): void
  {
    this.FilesToUpload = event.target.files;
  }

  UploadSelectedFiles(): void
  {
    this.isUploading = true;

    if (this.FilesToUpload) this.fileManagerService.PushFileToStorage(this.FilesToUpload, this.UploadRoute).then((result) =>
    {
      this.CloseDialogAndOpenConfirmationWithText('Files have been uploaded correctly');
    }).catch((error) =>
    {
      this.CloseDialogAndOpenConfirmationWithText('There was an error uploading the files: ' + error);
    });
  }

  CloseDialogAndOpenConfirmationWithText(text: string)
  {
    this.dialog.open(ConfirmationDialogComponent,
      {
        data:
        {
          mainText: text,
          confirmText: "Continue",
          canCancel: false,
        }
      }).afterClosed().subscribe(() =>
      {
        this.storageManager.LoadCurrentRoute();
        this.dialogRef.close();
      });
  }
}
