export class GameParameters
{
  coinsValues: number[];
  nextWeek: string;

  constructor(data:any)
  {
    this.coinsValues = data !== null ? Object.values(data.coinsValues) : [];
    this.nextWeek = data ? data.nextWeek : "Couln't retrieve next week data";
  }
}
