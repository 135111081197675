import { Directive, Input, OnInit } from "@angular/core";
import { BaseEvent } from "./base-event.model";

@Directive()

export class BaseEventDetail implements OnInit {
  public themes: any = [{ text: 'Amber', id: 0 }, { text: 'Green', id: 1 },
            { text: 'Blue', id: 2 }, { text: 'Peach', id: 3 },
            { text: 'Red', id: 4 }, { text: 'Purple', id: 5 },
            { text: 'Pink', id: 6 }, { text: 'Turqoise', id: 7 }];

  protected route: string = "PracticeGameParameters/";

  @Input() public currentEvent: BaseEvent = new BaseEvent(null);
  @Input() public currentEventIndex: number = 0;

  public ngOnInit(): void { }
  public UpdateEvent(): void { }
  public DeleteEvent(): void { }
  public CloneEvent(eve: BaseEvent) { }

  public trackByFn(index: any, item: any)
  {
    return index;
  }
}
