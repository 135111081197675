import {Component} from '@angular/core';
import {ZplnEvent} from '../../models/zplnEvent';
import * as FireB from "firebase/database";
import {Database} from "@angular/fire/database";
import { BaseEventList } from '../../../models/base-event-list.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../../../generic/components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-zpln-event-list',
  templateUrl: './zpln-event-list.component.html',
  styleUrls: ['./zpln-event-list.component.scss', '../../../../generic/style-sheets/event-button.scss']
})

export class ZplnEventListComponent extends BaseEventList {

  public ShowActiveEvents: boolean = true; 
  public ShowInactiveEvents: boolean = true; 

  override events: ZplnEvent[] = [];

  public override get Events() { return this.events; }
  public override get CurrentEvent() { return this.events[this.currentEventIndex]; }

  constructor(
    private fb: Database,
    public dialog: MatDialog,
  )
  {
    super();
    this.route = "Active_Tournaments/";
  }

  public get ActiveEventsCount(): number
  {
    let count: number = 0;
    for (let i = 0; i < this.events.length; i++) { if (this.events[i].isActiveToday) { ++count; } }

    return count;
  }

  public get InactiveEventsCount(): number
  {
    return this.events.length - this.ActiveEventsCount;
  }

  protected override GetActiveEvents(): void
  {
    FireB.get(FireB.ref(this.fb, this.route)).then((data) => {
      data.forEach((childSnapshot) =>
      {
        this.events.push(new ZplnEvent(childSnapshot.toJSON()));
      })

      this.events.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

    }).catch((error) =>
    {
      console.log("Could not retrieve " + this.route + " with error: " + error)
    });
  }

  public override CreateEvent(eve?: ZplnEvent): ZplnEvent
  {
    this.events.push(eve ? new ZplnEvent(eve) : new ZplnEvent(null));
    this.currentEventIndex = this.events.length - 1;
    if (eve) this.CurrentEvent.name = "COPY - " + eve.name;
    this.CurrentEvent.shouldUpdate = true;
    this.CurrentEvent.eventId = "EVENT_" + this.MakeId(20);

    return this.CurrentEvent;
  }

  private MakeId(length: number): string
  {
    let result:string = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  public override DeleteEvent(index: number): void
  {
    FireB.remove(FireB.ref(this.fb, this.route + this.events[index].eventId)).then(() =>
    {
      this.events.splice(index, 1);
      console.log("Event deleted correctly");
    }).catch((error) =>
    {
      console.log("Could not delete with error: " + error);
    });
  }

  public OpenConfirmationDialogToDelete(index: number) {
    this.dialog.open(ConfirmationDialogComponent, {
      data:
      {
        mainText: "Are you sure you want to delete event: " + this.events[index].name + " ?",
      }
    }).afterClosed().subscribe((result) => {
      if (result) { this.DeleteEvent(index); }
    });
  }

  IsEventVisible(event: ZplnEvent): boolean
  {
    return (event.isActiveToday == 1 && this.ShowActiveEvents) ||
           (event.isActiveToday == 0 && this.ShowInactiveEvents);
  }
}


