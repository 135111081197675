
<h1>Uploading files to: {{UploadRoute != '' ? UploadRoute : 'Root'}}</h1>
Select files to upload:

<label>
  <input type="file"
         class="text-nowrap text-truncate"
         (change)="SelectFile($event)" multiple />
</label>

<button class="btn btn-success btn-sm"
        [disabled]="!FilesToUpload || isUploading"
        (click)="UploadSelectedFiles()">
  <div *ngIf="!isUploading">
    Upload
  </div>
  <div *ngIf="isUploading">
    <svg style="display:inline-block" viewBox="25 25 50 50">
      <circle r="20" cy="50" cx="50"></circle>
    </svg>
  </div>
</button>

<div *ngIf="isUploading" style="display: block">
  You can continue browsing and get notified when the files get uploaded
</div>

