import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule} from "@angular/forms";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UsersListComponent } from './user/components/users-list/users-list.component';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideDatabase,getDatabase } from '@angular/fire/database';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import { provideFunctions,getFunctions } from '@angular/fire/functions';
import { provideStorage,getStorage } from '@angular/fire/storage';
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from '@angular/material/dialog';
import { GameParametersListComponent } from './game-parameters/components/game-parameters-list/game-parameters-list.component';
import { ZplnEventListComponent } from './events/zpln-event/components/zpln-event-list/zpln-event-list.component';
import { ZplnEventDetailComponent } from './events/zpln-event/components/zpln-event-detail/zpln-event-detail.component';
import { PracticeGamesListComponent } from './practice-games/components/practice-games-list/practice-games-list.component';
import { VaultDialogComponent } from './user/components/vault-dialog/vault-dialog.component';
import { ConfirmationDialogComponent } from './generic/components/confirmation-dialog/confirmation-dialog.component';
import { UserGameInfoDialogComponent } from './user/components/user-game-info-dialog/user-game-info-dialog.component';
import { LeaderboardsListComponent } from './leaderboards/components/leaderboards-list/leaderboards-list.component';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { LeaderoardDetailComponent } from './leaderboards/components/leaderoard-detail/leaderoard-detail.component';
import { StorageManagerComponent } from './storage-manager/components/storage-manager/storage-manager.component';
import { FileUploadDialogComponent } from './storage-manager/components/file-upload-dialog/file-upload-dialog.component';
import { StorageRouteExplorerComponent } from './storage-manager/components/storage-route-explorer/storage-route-explorer.component';
import { InputTextDialogComponent } from './generic/components/input-text-dialog/input-text-dialog.component';
import { AuthLoginComponent } from './auth-login/components/auth-login/auth-login.component';

@NgModule({
  declarations: [
    AppComponent,
    UsersListComponent,
    ZplnEventListComponent,
    ZplnEventDetailComponent,
    PracticeGamesListComponent,
    GameParametersListComponent,
    VaultDialogComponent,
    ConfirmationDialogComponent,
    UserGameInfoDialogComponent,
    LeaderboardsListComponent,
    LeaderoardDetailComponent,
    StorageRouteExplorerComponent,
    StorageManagerComponent,
    FileUploadDialogComponent,
    InputTextDialogComponent,
    AuthLoginComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideDatabase(() => getDatabase()),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    provideStorage(() => getStorage()),
    MatCardModule,
    MatDialogModule
  ],
  providers: [{ provide: FIREBASE_OPTIONS, useValue: environment.firebase }],
  bootstrap: [AppComponent],
})

export class AppModule { }
