import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../../generic/components/confirmation-dialog/confirmation-dialog.component';
import { InputTextDialogComponent } from '../../../generic/components/input-text-dialog/input-text-dialog.component';
import { FirebaseFile } from '../../models/firebase-file.model';
import { FileManagerService } from '../../services/file-manager/file-manager.service';
import { FileUploadDialogComponent } from '../file-upload-dialog/file-upload-dialog.component';

@Component({
  selector: 'app-storage-manager',
  templateUrl: './storage-manager.component.html',
  styleUrls: ['./storage-manager.component.scss']
})
export class StorageManagerComponent implements OnInit {

  public FirebaseFiles: FirebaseFile[] = [];
  public FirebaseFolders: Set<string> = new Set<string>();
  public StartingRoute: string = "";
  public Routes: string[] = [];
  public SearchFilter: string = "";
  public SelectedFiles: Set<string> = new Set<string>();
  public SelectedFolders: Set<string> = new Set<string>();


  public get CurrentRoute()
  {
    let fullRoute: string = "";
    for (let currentRoute of this.Routes)
    {
      fullRoute += currentRoute + '/';
    }

    return fullRoute.slice(0, -1);
  }

  constructor(
    private fileManagerService: FileManagerService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void
  {
    this.Routes.push(this.StartingRoute);
    this.LoadCurrentRoute();
  }

  public NavigateToFolder(folderName: string)
  {
    this.Routes.push(folderName);
    this.LoadCurrentRoute();
  }

  public GoToRouteIndex(index: number)
  {
    const numberOfJumps: number = this.Routes.length - index;
    this.Routes.splice(index + 1, numberOfJumps);
    this.LoadCurrentRoute();
  }

  public GoToPreviousFolder()
  {
    this.Routes.pop();
    this.LoadCurrentRoute();
  }

  public LoadCurrentRoute(): Promise<void>
  {
    this.ClearRoute();
    

    return this.fileManagerService.ListAllAtRoute(this.CurrentRoute).then((filesAndFolders) =>
    {
      this.FirebaseFiles = filesAndFolders.Files;
      this.FirebaseFolders = new Set<string>(filesAndFolders.Folders);
    });
  }

  private ClearRoute()
  {
    this.SelectedFiles.clear();
    this.SelectedFolders.clear();
    this.FirebaseFolders.clear();
    this.FirebaseFiles = [];
  }

  public OpenConfirmationDialogToRemoveFiles(): void
  {
    this.dialog.open(ConfirmationDialogComponent, {
      data:
      {
        mainText: "Are you sure you want to delete all selected elements?"
      }
    }).afterClosed().subscribe((result: boolean) => {
      if (result) { this.RemoveSelectedFilesAndFolders(); }
    });
  }

  private RemoveSelectedFilesAndFolders(): void
  {
    let removePromises: Promise<void>[] = [];

    const selectedFoldersToRemove: Set<string> = new Set<string>(this.SelectedFolders);

    removePromises.push(this.RemoveSelectedFolders());
    removePromises.push(this.RemoveSelectedFiles());

    Promise.all(removePromises).then((result) =>
    {
      this.LoadRouteAndRemoveDeletedFolders(selectedFoldersToRemove);
    }).catch((error) =>
    {
      alert(error);
    });
  }

  private LoadRouteAndRemoveDeletedFolders(selectedFoldersToRemove: Set<string>): void
  {
    this.LoadCurrentRoute().then(() =>
    {
      selectedFoldersToRemove.forEach((currentFolder) =>
      {
        const currentFolderName: string | undefined = currentFolder.split('/').pop();
        if (currentFolderName) this.FirebaseFolders.delete(currentFolderName);
      });
    });  
  }

  private RemoveSelectedFiles(): Promise<void>
  {
    const filesToDelete: string[] = Array.from(this.SelectedFiles.values());
    return this.fileManagerService.DeleteFiles(filesToDelete).then(() =>
    {
      this.SelectedFiles.clear();
    });
  }

  public RemoveSelectedFolders(): Promise<void>
  {
    const foldersToDelete: string[] = Array.from(this.SelectedFolders.values());
    return this.fileManagerService.DeleteFolders(foldersToDelete).then(() =>
    {
      this.SelectedFolders.clear();
    });
  }

  public IsFileSelected(name: string): boolean
  {
    return this.SelectedFiles.has(name);
  }

  public IsFolderSelected(name: string): boolean
  {
    return this.SelectedFolders.has(name);
  }

  SelectFile(event: any, fullFileRoute: string)
  {
    if (event.target.checked) this.SelectedFiles.add(fullFileRoute);
    else this.SelectedFiles.delete(fullFileRoute);
  }

  SelectFolder(event: any, fullFolderRoute: string)
  {
    if (event.target.checked) this.SelectedFolders.add(fullFolderRoute);
    else this.SelectedFolders.delete(fullFolderRoute);
  }

  public OpenDialogToUploadFiles(): void
  {
    this.dialog.open(FileUploadDialogComponent,
    {
      data:
      {
        uploadRoute: this.CurrentRoute,
        storageManager: this,
      }
    });
  }

  public OpenDialogToNameFolder(): void
  {
    this.dialog.open(InputTextDialogComponent, {
      data:
      {
        mainText: "Give the new folder a name:"
      }
    }).afterClosed().subscribe((result: string | null) =>
    {
      if (result !== null) { this.CreateNewFolderWithName(result) }
    });
  }

  private CreateNewFolderWithName(name: string): void
  {
    this.fileManagerService.CreateFolder(this.CurrentRoute + '/' + name).then(() =>
    {
      this.LoadCurrentRoute();
    }).catch((error) =>
    {
      alert(error);
    });
  }

  public SelectUnselectAll(): void
  {
    this.SelectUnselectFiles();
    this.SelectUnselectFolders();
  }

  private SelectUnselectFiles(): void
  {
    this.FirebaseFiles.forEach((currentFile) =>
    {
      if (this.IsFileSelected(currentFile.FullPath)) this.SelectedFiles.delete(currentFile.FullPath);
      else this.SelectedFiles.add(currentFile.FullPath);
    });
  }

  private SelectUnselectFolders(): void
  {
    this.FirebaseFolders.forEach((currentFolder) =>
    {
      const targetRoute: string = this.CurrentRoute + '/' + currentFolder;
      if (this.IsFolderSelected(targetRoute)) this.SelectedFolders.delete(targetRoute);
      else this.SelectedFolders.add(targetRoute);
    });
  }
}
