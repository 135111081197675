import { Injectable } from '@angular/core';
import { AngularFireDatabase, QueryFn, SnapshotAction } from '@angular/fire/compat/database';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { firstValueFrom, map } from 'rxjs';
import { LeaderboardUser } from '../models/leaderboard-user.model';
import { Leaderboard } from '../models/leaderboard.model';
import * as FireB from "firebase/database";
import { Database, DataSnapshot } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})

export class LeaderboardService {

  constructor(
    private realtime: Database,
    private db: AngularFireDatabase,
    private firestore: AngularFirestore,
  ) { }

  public async GetLeaderboardsForRoutes(route: string, queryRef: QueryFn): Promise<Leaderboard[]>
  {
    let leaderBoardPromises: Promise<Leaderboard>[] = [];
    let listResult = await firstValueFrom(this.db.list(route, queryRef).snapshotChanges());

    
    listResult?.forEach((currentEvent) =>
    {
      if (this.isEventActive(currentEvent) &&
          !this.isEventHighScore(currentEvent) &&
          !this.isEventJackpot(currentEvent))
      {
        if (currentEvent.key != null)
          leaderBoardPromises.push(this.GetLeaderboardFromId(currentEvent.key, currentEvent.payload.exportVal().name,
                                   (ref: any) => ref.orderBy('total', 'desc').limit(25)));
      }
    });

    return Promise.all(leaderBoardPromises);
  }

  private isEventActive(event: SnapshotAction<unknown>): boolean
  {
    return event.payload.exportVal().isActiveToday == 1;
  }

  private isEventHighScore(event: SnapshotAction<unknown>): boolean
  {
    return event.payload.exportVal().isHighScoreEvent;
  }

  private isEventJackpot(event: SnapshotAction<unknown>): boolean
  {
    return event.payload.exportVal().isJackpotEvent;
  }

  public async GetLeaderboardFromId(id: string, eventName: any, ref: any) : Promise<Leaderboard>
  {
    
    const currentEventRef: AngularFirestoreCollection<LeaderboardUser> = await this.firestore.collection('/' + id, ref);
    let mappedUsers = await this.MappedUsers(currentEventRef);
    const leaderBoardUsers = await this.LeaderboardUsersFromData(mappedUsers);

    return new Leaderboard(id, eventName, leaderBoardUsers);
  }

  private async MappedUsers(firestoreCollection: AngularFirestoreCollection<LeaderboardUser>)
  {
    let mappedUsers = await firestoreCollection.get().pipe(map((changes) =>
      changes.docs.map((c) =>
      (
        {
          id: c.id,
          globalAverage: c.data().globalAverage,
          total: c.data().total,
          exists: true
        }
      )))).toPromise();

    return mappedUsers;
  }

  private async LeaderboardUsersFromData(usersLeaderBoardFirestore: any[] | undefined): Promise<LeaderboardUser[]>
  {
    let userPromises: Promise<DataSnapshot>[] = [];
    let leaderBoardUsers: LeaderboardUser[] = [];

    usersLeaderBoardFirestore?.forEach((currentUser) =>
    {
      userPromises.push(FireB.get(FireB.ref(this.realtime, 'Users/' + currentUser.id)).then());
      leaderBoardUsers.push(new LeaderboardUser(currentUser));
    });


    const usersRealtime = await Promise.all(userPromises);
    usersRealtime.forEach((data, i) =>
    {
      if (data.exportVal() && data.exportVal().userName) leaderBoardUsers[i].name = data.exportVal().userName;
      else leaderBoardUsers[i].exists = false;
    });

    return leaderBoardUsers;
  }
}
