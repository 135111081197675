<h1>Login to continue</h1>
<mat-card>
  <table style="align-content:center">
    <tr>
      <td>
        <label for="name">Email</label>
      </td>
      <td>
        <input type="text" style="color:black" />
        <!-- (ngModelChange)="currentEvent.shouldUpdate = true; currentEvent.brandLogoImage = FullStorageRoute($event);"
  [ngModel]="SimpleStorageRoute(currentEvent.brandLogoImage)" name="brandLogoImage" /> -->
      </td>
    </tr>
    <tr>
      <td>
        <label for="name">Password</label>
      </td>
      <td>
        <input type="password" style="color:black" />
      </td>
    </tr>
  </table>
</mat-card>
