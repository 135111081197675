<div class="row">
  <div style="width: 35%">
    <ng-container *ngIf="(ActiveLeaderBoards && ActiveLeaderBoards.length>0) || (HistoricLeaderBoards && HistoricLeaderBoards.length>0)">
      <div class="event-list-header">
        <h2>Leaderboards</h2>
      </div>
      <div>
        <button class="event-group" (click)="ShowActiveLeaderBoards = !ShowActiveLeaderBoards;">
          <b> Current Leaderboards ({{ActiveLeaderBoards.length}}) </b>
          <span [ngClass]="ShowActiveLeaderBoards ? 'triangle-down' : 'triangle'" style="float: right;"></span>
        </button>

        <div *ngIf="ShowActiveLeaderBoards">
          <div *ngFor="let currentLeaderBoard of ActiveLeaderBoards; let i = index" [attr.data-index]="i">
            <div class="leaderboard-list-ind">
              <button [ngClass]="currentLeaderBoard === CurrentLeaderboard ? 'event-button-selected' : 'event-button-unselected'"
                      (click)="EditLeaderBoard(currentLeaderBoard)">
                <div>{{currentLeaderBoard.eventName}} </div>
              </button>
            </div>
          </div>
        </div>

        <button class="event-group" (click)="ShowHistoricLeaderboards = !ShowHistoricLeaderboards;">
          <b> Historic Leaderboards ({{HistoricLeaderBoards.length}}) </b>
          <span [ngClass]="ShowHistoricLeaderboards ? 'triangle-down' : 'triangle'" class="triangle" style="float: right;"></span>
        </button>

        <div *ngIf="ShowHistoricLeaderboards">
          <div *ngFor="let currentLeaderBoard of HistoricLeaderBoards; let i = index" [attr.data-index]="i">
            <div class="leaderboard-list-ind">
              <button [ngClass]="currentLeaderBoard === CurrentLeaderboard ? 'event-button-selected' : 'event-button-unselected'"
                      (click)="EditLeaderBoard(currentLeaderBoard)">
                <div>{{currentLeaderBoard.eventName}} </div>
                <div>{{currentLeaderBoard.leaderBoardId}} </div>
              </button>
            </div>
          </div>
        </div>

      </div>
    </ng-container>
  </div>

  <div *ngIf="CurrentLeaderboard != null && IsLeaderboardVisible" class="event-edit">
    <app-leaderoard-detail [CurrentLeaderboard]="CurrentLeaderboard">
    </app-leaderoard-detail>
  </div>
</div>
