<h1>{{currentEvent.name}}</h1>
<h3>ID: {{currentEvent.eventId}}</h3>

<mat-card>

  <table class="action-buttons-group">
    <tr>
      <td *ngIf="currentEvent.shouldUpdate;">
        <button style="color:red;" class="action-button" (click)="UpdateEvent()">Upload</button>
      </td>
      <td>
        <button class="action-button" (click)="CloneEvent(currentEvent)">Clone</button>
      </td>
      <td>
        <button class="action-button" (click)="DeleteEvent()">Delete</button>
      </td>
    </tr>
  </table>

  <div class="detail-main">
    <table style="float:left">
      <tbody>
        <tr>
          <td>
            <label>Event Name</label>
          </td>
          <td>
            <input style="width:20em" type="text" class="form-control" id="name"
                   required [(ngModel)]="this.currentEvent.name" (ngModelChange)="currentEvent.shouldUpdate =true;" name="name" />
          </td>
        </tr>
        <tr>
          <td>
            <label>Event Type</label>
          </td>
          <td>
            <select class="form-control" id="type" required (ngModelChange)="currentEvent.shouldUpdate = true;" [(ngModel)]="currentEvent.eventType">
              <option *ngFor="let type of types" [ngValue]="type.id">{{type.text}}</option>
            </select>
          </td>
        </tr>
      </tbody>
    </table>

    <table style="float: right">
      <tbody>
        <tr>
          <td>
            <label>Auto Renewal</label>
          </td>
          <td>
            <label class="switch">
              <input type="checkbox" class="form-control" id="autoRenewal"
                     name="autoRenewal" (ngModelChange)="currentEvent.shouldUpdate = true;" [(ngModel)]="this.currentEvent.autoRenewal" />
              <span class="slider"></span>
            </label>

          </td>
        </tr>
        <tr>
          <td>
            <label>Is Active Today</label>
          </td>
          <td>
            <label class="switch">
              <input type="checkbox" class="form-control" id="isActiveToday"
                     name="isActiveToday" (ngModelChange)="currentEvent.shouldUpdate = true;" [(ngModel)]="this.currentEvent.isActiveToday" />
              <span class="slider"></span>
            </label>
          </td>
        </tr>
        <tr>
          <td>
            <label for="isZplnEvent">Is Zpln Event</label>
          </td>
          <td>
            <label class="switch">
              <input type="checkbox" class="form-control" id="isZplnEvent"
                     name="isZplnEvent" (ngModelChange)="currentEvent.shouldUpdate = true;" [(ngModel)]="this.currentEvent.isZplnEvent" [ngModelOptions]="{standalone: true}" />
              <span class="slider"></span>
            </label>
          </td>
        </tr>
        <tr>
          <td>
            <label for="isZplnEvent">Is Brand Event</label>
          </td>
          <td>
            <label class="switch">
              <input type="checkbox" class="form-control" id="isBrandEvent"
                     name="isBrandEvent" (ngModelChange)="currentEvent.shouldUpdate = true;" [(ngModel)]="this.currentEvent.isBrandEvent" [ngModelOptions]="{standalone: true}" />
              <span class="slider"></span>
            </label>
          </td>
        </tr>
        <tr>
          <td>
            <label for="isZplnEvent">Is HighScore Event</label>
          </td>
          <td>
            <label class="switch">
              <input type="checkbox" class="form-control" id="isHighScoreEvent"
                     name="isHighScoreEvent" (ngModelChange)="currentEvent.shouldUpdate = true;" [(ngModel)]="this.currentEvent.isHighScoreEvent" [ngModelOptions]="{standalone: true}" />
              <span class="slider"></span>
            </label>
          </td>
        </tr>
        <tr>
          <td>
            <label for="isZplnEvent">Is Jackpot Event</label>
          </td>
          <td>
            <label class="switch">
              <input type="checkbox" class="form-control" id="isJackpotEvent"
                     name="isJackpotEvent" (ngModelChange)="currentEvent.shouldUpdate = true;" [(ngModel)]="this.currentEvent.isJackpotEvent" [ngModelOptions]="{standalone: true}" />
              <span class="slider"></span>
            </label>
          </td>
        </tr>
      </tbody>
    </table>

    <table>
      <tbody>
        <tr>
          <td>
            <label for="coinsCost">Coins Cost</label>
          </td>
          <td>
            <input type="number" class="form-control" id="coinsCost"
                   required
                   (ngModelChange)="currentEvent.shouldUpdate = true;" [(ngModel)]="this.currentEvent.coinsCost" name="coinsCost" />
          </td>
        </tr>
        <tr>
          <td>
            <label for="coinsJackpot">Coins Jackpot</label>
          </td>
          <td>
            <input type="number" class="form-control" id="coinsCost"
                   required
                   (ngModelChange)="currentEvent.shouldUpdate = true;" [(ngModel)]="this.currentEvent.coinsJackpot" name="coinsJackpot" />
          </td>
        </tr>
      </tbody>
    </table>

    <table>
      <tbody>
        <tr>
          <td>
            <label for="startDate">Start Date</label>
          </td>
          <td>
            <input type="date" class="form-control" id="startDate"
                   required
                   (ngModelChange)="currentEvent.shouldUpdate = true; StartDate = $event;" [ngModel]="StartDate | date:'yyyy-MM-dd'" name="startDate" />
          </td>
          <td *ngIf="IsEventActiveAndDateNotToday(currentEvent)" style="color:red">
            Event is active today but start date is over today
          </td>
        </tr>
        <tr>
          <td>
            <label for="startTime">Start Time</label>
          </td>
          <td>
            <input type="time" class="form-control" id="startTime"
                   required
                   (ngModelChange)="currentEvent.shouldUpdate = true; StartTime = $event;" [ngModel]="StartDate | date:'HH:mm'" name="startTime" />
          </td>
        </tr>
        <tr>
          <td>
            <label for="remainsHours">Days Remaining</label>
          </td>
          <td>
            <input type="number" class="form-control" id="remainsHours"
                   required
                   (ngModelChange)="currentEvent.shouldUpdate = true;" [(ngModel)]="this.RemainingDays" name="remainsHours" />
          </td>
          <td style="color:red" *ngIf="!currentEvent.isActiveToday && RemainingDays < 1">
            Should be at least 1 day for next events
          </td>
        </tr>
      </tbody>
    </table>

    <table>
      <tbody>
        <tr>
          <td>
            <label for="name">Brand Logo Image</label>
          </td>
          <td>
            <input type="text" class="form-control" id="brandLogoImage"
                   required
                   (ngModelChange)="currentEvent.shouldUpdate = true; currentEvent.brandLogoImage = FullStorageRoute($event);"
                   [ngModel]="SimpleStorageRoute(currentEvent.brandLogoImage)" name="brandLogoImage" />
          </td>
          <td>
            <button (click)="RouteExplorerDialog(StorageImageRoute ,'brandLogoImage')">Edit</button>
          </td>
        </tr>
        <tr>
          <td>
            <label for="name">Brand Text Image</label>
          </td>
          <td>
            <input type="text" class="form-control" id="brandTextImage"
                   required
                   (ngModelChange)="currentEvent.shouldUpdate = true; currentEvent.brandTextImage = FullStorageRoute($event);"
                   [ngModel]="SimpleStorageRoute(currentEvent.brandTextImage)" name="brandTextImage" />
          </td>
          <td>
            <button (click)="RouteExplorerDialog(StorageImageRoute ,'brandTextImage')">Edit</button>
          </td>
        </tr>

        <tr>
          <td>
            <label for="name">Main Image</label>
          </td>
          <td>
            <input type="text" class="form-control" id="creditprizeScreen"
                   required
                   (ngModelChange)="currentEvent.shouldUpdate = true; currentEvent.creditprizeScreen = FullStorageRoute($event);"
                   [ngModel]="SimpleStorageRoute(currentEvent.creditprizeScreen)" name="creditprizeScreen" />
          </td>
          <td>
            <button (click)="RouteExplorerDialog(StorageImageRoute ,'creditprizeScreen')">Edit</button>
          </td>
        </tr>

        <tr>
          <td>
            <label for="name">Background Image</label>
          </td>
          <td>
            <input type="text" class="form-control" id="backgroundImage"
                   required
                   (ngModelChange)="currentEvent.shouldUpdate = true; currentEvent.backgroundImage = FullStorageRoute($event);"
                   [ngModel]="SimpleStorageRoute(currentEvent.backgroundImage)" name="backgroundImage" />
          </td>
          <td>
            <button (click)="RouteExplorerDialog(StorageImageRoute ,'backgroundImage')">Edit</button>
          </td>
        </tr>

        <tr>
          <td>
            <label for="name">Video URL</label>
          </td>
          <td>
            <input type="text" class="form-control" id="videoURL"
                   required
                   (ngModelChange)="currentEvent.shouldUpdate = true;" [(ngModel)]="currentEvent.videoURL" name="videoURL" />
          </td>
          <td>
            <button (click)="RouteExplorerDialog(StorageVideoRoute ,'videoURL', true)">Edit</button>
          </td>
        </tr>

      </tbody>
    </table>

    <div class="songs-list">
      <h2>Songs</h2>
      <table class="detail-table">
        <thead>
          <tr>
            <th>Game</th>
            <th>Song</th>
            <th>Action</th>
            <th>Theme</th>
            <th>Seed</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let currentSong of this.currentEvent.gameSongs;  let i = index;" [attr.data-index]="i">
            <th>{{ i+1 }}</th>
            <td>
              <input type="text" class="form-control" id="song_{{i}}" #song
                     required (ngModelChange)="currentEvent.shouldUpdate = true; currentEvent.gameSongs[i] = FullStorageRoute($event);"
                     [ngModel]="SimpleStorageRoute(currentSong)"
                     name="song_{{i}}" />
            </td>
            <td>
              <button (click)="RouteExplorerDialog(StorageMusicRoute ,'gameSongs', false, i)">Edit</button>
            </td>
            <td>
              <select type="number" class="form-control" id="seed_{{i}}" #theme
                      required (ngModelChange)="currentEvent.shouldUpdate = true;" [(ngModel)]="this.currentEvent.gameThemes[i]" name="theme_{{i}}">
                <option *ngFor="let theme of themes" [ngValue]="theme.id">{{theme.text}}</option>
              </select>
            </td>
            <td>
              <input type="number" class="form-control" id="seed_{{i}}" #seed
                     required (ngModelChange)="currentEvent.shouldUpdate = true;" [(ngModel)]="this.currentEvent.gameSeeds[i]" name="seed_{{i}}" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="prizes-list">
      <h2>Prizes</h2>
      <table class="detail-table">
        <thead>
          <tr>
            <th>Rank</th>
            <th *ngIf="currentEvent.eventType === 4 || currentEvent.eventType === 5">
              Cash
            </th>
            <th *ngIf="currentEvent.eventType === 1 || currentEvent.eventType === 6">
              Coins
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of this.currentEvent.prizes;  let i = index" [attr.data-index]="i">
            <th>{{ item.rank }}</th>
            <td *ngIf="currentEvent.eventType === 4 || currentEvent.eventType === 5">
              <input type="number" class="form-control" id="cash{{i}}"
                     required
                     (ngModelChange)="currentEvent.shouldUpdate = true;" [(ngModel)]="item.cash" name="cash{{i}}" />
            </td>
            <td *ngIf="currentEvent.eventType === 1 || currentEvent.eventType === 6">
              <input type="number" class="form-control" id="coins_{{i}}"
                     required
                     (ngModelChange)="currentEvent.shouldUpdate = true;" [(ngModel)]="item.coins" name="coins{{i}}" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</mat-card>
