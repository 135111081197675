export class PracticeGame
{
  public gameSongRoute: string;
  public gameThemeIndex: number;

  constructor(gameSongRoute: string, gameThemeIndex: number)
  {
    this.gameSongRoute = gameSongRoute;
    this.gameThemeIndex = gameThemeIndex;
  }
}
