import { LeaderboardUser } from "./leaderboard-user.model";

export class Leaderboard {

  public leaderBoardId: string;
  public eventName: string;
  public leaderboardUsers: LeaderboardUser[];

  constructor(id: string, eventName: string, usersData: LeaderboardUser[])
  {
    this.leaderBoardId = id;
    this.leaderboardUsers = usersData;
    this.eventName = eventName;
  }
}
