import { Component, OnInit } from '@angular/core';
import { Leaderboard } from '../../models/leaderboard.model';
import { LeaderboardService } from '../../services/leaderboard.service';

@Component({
  selector: 'app-leaderboards-list',
  templateUrl: './leaderboards-list.component.html',
  styleUrls: ['./leaderboards-list.component.scss',
              '../../../generic/style-sheets/event-button.scss']
})
export class LeaderboardsListComponent implements OnInit {

  private activeEventsRoute: string = "/Active_Tournaments";
  private historicEventsRoute: string = "/Historic_Tournaments";
  private jackpotLeaderboardId: string = "jackpot_leaderboard";
  private highscoreLaderboardId: string = "highscore_leaderboard";
  private jackpotLeaderboardName: string = "Jackpot Leaderboard";
  private highscoreLeaderboardName: string = "Highscore Leaderboard";

  private activeLeaderboards: Leaderboard[] = [];
  private historicLeaderboards: Leaderboard[] = [];

  public CurrentLeaderboard: Leaderboard | null = null;
  public ShowActiveLeaderBoards: boolean = true;
  public ShowHistoricLeaderboards: boolean = true;

  public get ActiveLeaderBoards() { return this.activeLeaderboards; }
  public get HistoricLeaderBoards() { return this.historicLeaderboards; }

  public get IsLeaderboardVisible(): boolean
  {
    return this.ShowActiveLeaderBoards && this.activeLeaderboards.includes(this.CurrentLeaderboard!) ||
           this.ShowHistoricLeaderboards && this.historicLeaderboards.includes(this.CurrentLeaderboard!);
  }

  constructor(
    private leaderboardService: LeaderboardService)
  { }

  ngOnInit(): void
  {
    this.GetLeaderboards();
  }

  private GetLeaderboards()
  {
    this.leaderboardService.GetLeaderboardsForRoutes(this.activeEventsRoute, ref => { return ref; }).then((leaderboards) =>
    { this.activeLeaderboards = this.activeLeaderboards.concat(leaderboards); });

    this.leaderboardService.GetLeaderboardsForRoutes(this.historicEventsRoute, ref => { return ref.limitToFirst(100); }).then((leaderboards) =>
    { this.historicLeaderboards = this.historicLeaderboards.concat(leaderboards) });

    this.leaderboardService.GetLeaderboardFromId(this.jackpotLeaderboardId, this.jackpotLeaderboardName, (ref: any) => ref.orderBy('globalAverage', 'desc').limit(25)).then((leaderboard) =>
    { this.activeLeaderboards.push(leaderboard) });

    this.leaderboardService.GetLeaderboardFromId(this.highscoreLaderboardId, this.highscoreLeaderboardName, (ref: any) => ref.orderBy('globalAverage', 'desc').limit(25)).then((leaderboard) =>
    { this.activeLeaderboards.push(leaderboard) });
  }

  public EditLeaderBoard(leaderBoard: Leaderboard): void
  {
    this.CurrentLeaderboard = leaderBoard;
  }
}
