import { Directive, OnInit } from "@angular/core";
import { BaseEvent } from "./base-event.model";

@Directive()

export class BaseEventList implements OnInit
{
  protected events: BaseEvent[] = [];
  protected currentEventIndex: number = 0;
  protected route: string = "";

  public get Events() { return this.events; }
  public get CurrentEvent() { return this.events[this.currentEventIndex]; }
  public get CurrentEventIndex() { return this.currentEventIndex }

  constructor(
  ) { }

  public ngOnInit()
  {
    this.GetActiveEvents();
  }

  protected GetActiveEvents(): void { }

  public CreateEvent(eve?: BaseEvent): void { }
  public DeleteEvent(index: number): void { }

  public EditEvent(index: number): void
  {
    this.currentEventIndex = index;
  }
}
