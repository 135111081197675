import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-input-text-dialog',
  templateUrl: './input-text-dialog.component.html',
  styleUrls: ['./input-text-dialog.component.scss']
})
export class InputTextDialogComponent implements OnInit {

  public mainText!: string;
  public confirmText!: string;
  public cancelText!: string;
  public canCancel!: boolean;
  public inputText!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<InputTextDialogComponent>
  )
  {
    this.dialogRef.disableClose = true;
    this.mainText = this.data.mainText ? this.data.mainText : "Input desired text";
    this.confirmText = this.data.confirmText ? this.data.confirmText : "Confirm";
    this.cancelText = this.data.cancelText ? this.data.cancelText : "Cancel";
    this.canCancel = this.data.canCancel != null ? this.data.canCancel : true;
    this.inputText = '';
  }

  ngOnInit(): void { }

  CloseWithValue(value: string | null)
  {
    this.dialogRef.close(value);
  }
}
