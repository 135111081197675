<div class="row">
  <div style="width: 35%">
    <ng-container *ngIf="Events && Events.length>0">
      <div class="event-list-header">
        <h2>Events ({{Events.length}}):</h2>
      </div>
      <div>
        <button class="event-group" (click)="ShowActiveEvents = !ShowActiveEvents;">
          <b> Active Events ({{ActiveEventsCount}}) </b>
          <span [ngClass]="ShowActiveEvents ? 'triangle-down' : 'triangle'" class="triangle" style="float: right;"></span>
        </button>

        <div *ngIf="ShowActiveEvents">
          <div *ngFor="let eve of Events; let i = index" [attr.data-index]="i">
            <div *ngIf="eve.isActiveToday" [ngClass]="eve.shouldUpdate ? 'event-modified' : 'event-ready'">
              <button [ngClass]="eve === CurrentEvent ? 'event-button-selected' : 'event-button-unselected'"
                      (click)="EditEvent(i)">
                <div>Name: <b> {{eve.name}} </b></div>
                <div>Start Date: <b> {{eve.startDate | date: 'long'}} </b></div>
              </button>
              <p *ngIf="eve.shouldUpdate" style="color: red; text-align:center"> Upload changes to save</p>
            </div>
          </div>
        </div>

        <button class="event-group" (click)="ShowInactiveEvents = !ShowInactiveEvents;">
          <b> Inactive Events ({{InactiveEventsCount}}) </b>
          <span [ngClass]="ShowInactiveEvents ? 'triangle-down' : 'triangle'" class="triangle" style="float: right;"></span>
        </button>

        <div *ngIf="ShowInactiveEvents">
          <div *ngFor="let eve of Events; let i = index" [attr.data-index]="i">
            <div *ngIf="!eve.isActiveToday" [ngClass]="eve.shouldUpdate ? 'event-modified' : 'event-ready'">
              <button [ngClass]="eve === CurrentEvent ? 'event-button-selected' : 'event-button-unselected'"
                      (click)="EditEvent(i)">
                <div> Name: <b> {{eve.name}} </b></div>
                <div>Start Date: <b> {{eve.startDate | date: 'long'}} </b></div>
              </button>
              <p *ngIf="eve.shouldUpdate" style="color: red; text-align:center"> Upload changes to save</p>
            </div>
          </div>
        </div>
      </div>

    </ng-container>

    <div>
      <button (click)="CreateEvent()">Create Event</button>
    </div>

  </div>
<div *ngIf="CurrentEvent != null && IsEventVisible(CurrentEvent)" class="event-edit">
  <app-zpln-event-detail [currentEventIndex]="CurrentEventIndex"
                         [currentEvent]="CurrentEvent">
  </app-zpln-event-detail>
</div>
</div>
