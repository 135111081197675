<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.1/css/all.min.css" integrity="sha256-2XFplPlrFClt0bIdPgpz8H7ojnk10H69xRqd9+uTShA=" crossorigin="anonymous" />
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/ionicons/4.5.6/css/ionicons.min.css" integrity="sha512-0/rEDduZGrqo4riUlwqyuHDQzp2D1ZCgH/gFIfjMIL5az8so6ZiXyhf1Rg8i6xsjv+z/Ubc4tt1thLigEcu6Ug==" crossorigin="anonymous" referrerpolicy="no-referrer" />
<div class="container flex-grow-1 light-style container-p-y">
  <div class="container-m-nx container-m-ny bg-lightest mb-3">
    <ol class="breadcrumb text-big container-p-x py-3 m-0">
      <li *ngFor="let currentRoute of Routes;  let i = index;" [attr.data-index]="i" class="breadcrumb-item">
        <a (click)="GoToRouteIndex(i)">{{currentRoute}}</a>
      </li>
    </ol>

    <hr class="m-0" />

    <div class="file-manager-actions container-p-x py-2">
      <div style="margin-left: auto; margin-right: 0">
        <div style="margin-left: 2em; margin-right: 2em;" class="icone lupa"></div>
        <input type="text" style="color:black;" [(ngModel)]="SearchFilter" />
      </div>
    </div>

    <hr class="m-0" />
  </div>

  <div style="background-color: #242424" class="file-manager-container file-manager-col-view">
    <div class="file-manager-row-header">
      <div class="file-item-name pb-2">Filename</div>
      <div class="file-item-changed pb-2">Changed</div>
    </div>

    <div *ngIf="StartingRoute != CurrentRoute">
      <div class="file-item" (click)="GoToPreviousFolder()">
        <div class="file-item-icon file-item-level-up fas fa-level-up-alt text-secondary"></div>
        <a class="file-item-name">
          ..
        </a>
      </div>
    </div>

    <div *ngFor="let currentFolder of FirebaseFolders;  let i = index;" [attr.data-index]="i">
        <div class="file-item" (click)="NavigateToFolder(currentFolder)">
          <div class="file-item-icon far fa-folder text-secondary"></div>
          <a class="file-item-name">
            {{currentFolder}}
          </a>
        </div>
    </div>

    <div *ngFor="let currentFile of FirebaseFiles;  let i = index;" [attr.data-index]="i">
      <div *ngIf="currentFile.Name.toUpperCase().includes(SearchFilter.toUpperCase()) && currentFile.Type != 4">
        <div (click)="SelectedElement = currentFile" class="file-item" [ngStyle]="{'border-color': IsFileSelected(currentFile.FullPath) ? 'red' : ''}">
          <div *ngIf="currentFile.Type == 1" class="file-item-icon far fa-file-video text-secondary"></div>
          <div *ngIf="currentFile.Type == 2" class="file-item-icon far fa-file-audio text-secondary"></div>
          <div *ngIf="currentFile.Type == 0" class="file-item-img" style="background-image: url({{currentFile.DownloadURL}}); "></div>
          <a [ngStyle]="{'text-decoration': IsFileSelected(currentFile.FullPath) ? 'underline red' : ''}" class="file-item-name">
            {{currentFile.Name}}
          </a>
        </div>
      </div>
    </div>
  </div>

  <br />
  <hr class="m-0" />
  <div>
    <b style="">Selected Element: </b> {{SelectedElementRoute}}
  </div>

<div class="bottom">
  <div class="bottom-right">
    <button [ngStyle]="{'background-color': (SelectedElement == null) ? 'darkgrey' : ''}"
            [disabled]="SelectedElement == null" (click)="ConfirmDialogRoute()">Confirm</button>
    <button (click)="CloseDialog()">Cancel</button>
  </div>
</div>
</div>
