<h2>Next week starts at: {{GameParameters.nextWeek}}</h2>
<h3>Coin Value Per Rank</h3>
<div class="game-param-container">
  <div [ngClass]="NeedsUpdate ? 'event-modified' : 'event-ready'">
    <mat-card>
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Coin Value</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let coinValue of GameParameters.coinsValues;  let i = index; trackBy: trackByFn" [attr.data-index]="i">
            <td>{{ i+1 }}</td>
            <td>
              <input type="number" required (ngModelChange)="NeedsUpdate = true" [(ngModel)]="GameParameters.coinsValues[i]" />
            </td>
          </tr>
        </tbody>
      </table>
    </mat-card>
    <div *ngIf="NeedsUpdate" style="color:red; margin: 1em;">
      Upload to save
      <button (click)="UpdateGameParameters()">Upload</button>
    </div>
  </div>
</div>
<br>

