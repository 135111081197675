import { Component, Inject, OnInit } from '@angular/core';
import { ListResult, Storage } from "@angular/fire/storage";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VaultDialogComponent } from '../../../user/components/vault-dialog/vault-dialog.component';
import { FirebaseFile } from '../../models/firebase-file.model';
import { FileManagerService } from '../../../storage-manager/services/file-manager/file-manager.service';

@Component({
  selector: 'app-firebase-explorer',
  templateUrl: './storage-route-explorer.component.html',
  styleUrls: ['./storage-route-explorer.component.scss']
})
export class StorageRouteExplorerComponent implements OnInit {

  public FirebaseFiles: FirebaseFile[] = [];
  public FirebaseFolders: string[] = [];
  public SelectedElement: FirebaseFile | null = null;
  public SearchFilter: string = "";
  public Routes: string[] = [];
  public StartingRoute: string = "";

  public get CurrentRoute()
  {
    let fullRoute: string = "";
    for (let currentRoute of this.Routes)
    {
      fullRoute += currentRoute + '/';
    }

    return fullRoute.slice(0, -1);
  }

  public get SelectedElementRoute()
  {
    if (this.SelectedElement != null)
    {
      return this.SelectedElement.FullPath;
    }
    else
    {
      return "";
    }
    
  }

  constructor(
    private storage: Storage,
    private fileManagerService: FileManagerService,
    public dialogRef: MatDialogRef<VaultDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void
  {
    this.StartingRoute = this.data.route;
    this.Routes.push(this.StartingRoute);
    this.LoadCurrentRoute();
  }

  public GoToRouteIndex(index: number)
  {
    const numberOfJumps: number = this.Routes.length - index;
    this.Routes.splice(index + 1, numberOfJumps);
    this.LoadCurrentRoute();
  }

  public GoToPreviousFolder()
  {
    this.Routes.pop();
    this.LoadCurrentRoute();
  }

  public NavigateToFolder(folderName: string)
  {
    this.Routes.push(folderName);
    this.LoadCurrentRoute();
  }

  private LoadCurrentRoute(): void
  {
    this.FirebaseFiles = [];
    this.FirebaseFolders = [];

    this.fileManagerService.ListAllAtRoute(this.CurrentRoute).then((data) =>
    {
      this.FirebaseFiles = data.Files;
      this.FirebaseFolders = data.Folders;
    });
  }

  public ConfirmDialogRoute(): void
  {
    if (this.SelectedElement) this.dialogRef.close(this.SelectedElement);
  }

  public CloseDialog(): void
  {
    this.dialogRef.close();
  }

  public IsFileSelected(fileFullPath: string): boolean
  {
    return fileFullPath == this.SelectedElementRoute;
  }
}
