import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'my-app';

  //// TABS TO INTRODUCE { text: 'Login', url: '/login' } ----- { text: 'Reports', url: '/reports' }


  public tabs: any = [{ text: 'Events', url: '/events'}, { text: 'Users', url: '/users'},
                      { text: 'Game Parameters', url: '/game-parameters' }, { text: 'Practice', url: '/practice-games' }, 
                      { text: 'Leaderboards', url: '/leaderboards' }, { text: 'Storage', url: '/storage' } ];

  public currentUrl: string = "";

  constructor(
    public router: Router,
  )
  {
    this.SetCurrentUrlOnLoad();
  }

  private SetCurrentUrlOnLoad(): void
  {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(url =>
      {
        this.currentUrl = this.router.url;
      });
  }
}
