import { Component, Input, OnInit } from '@angular/core';
import { Leaderboard } from '../../models/leaderboard.model';

@Component({
  selector: 'app-leaderoard-detail',
  templateUrl: './leaderoard-detail.component.html',
  styleUrls: ['./leaderoard-detail.component.scss']
})
export class LeaderoardDetailComponent implements OnInit {

  constructor() { }

  @Input() public CurrentLeaderboard: Leaderboard = new Leaderboard("", "", []);

  ngOnInit(): void
  {

  }
}
