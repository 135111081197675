import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-user-game-info-dialog',
  templateUrl: './user-game-info-dialog.component.html',
  styleUrls: ['./user-game-info-dialog.component.scss']
})
export class UserGameInfoDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserGameInfoDialogComponent>
  )
  {

  }

  ngOnInit(): void
  {

  }

  Close(): void
  {
    this.dialogRef.close();
  }
}
