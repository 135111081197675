export class LeaderboardUser
{
  name: string;
  total: number;
  globalAverage: number;
  exists: boolean;

  constructor(data: any)
  {
    this.name = data.id;
    this.total = data.total;
    this.globalAverage = data.globalAverage;
    this.exists = true;
  }
}
